import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";
import FormOverlay from "../components/form-overlay";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const WebinarDetail = ({ data }) => {
  const { webinar } = data;
  const [show, setShow] = useState(false);

  return (
    <Layout>
      <Seo
        title={webinar.title}
        description={webinar.description}
        image={webinar.image.url}
        form={true}
      />

      <div className="page-detail">
        <div className="hero">
          <div className="container-lg position-relative">
            <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
            <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
            <div className="row">
              <div className="col-10 col-md-8">
                <h1 className="hero__title">{webinar.title}</h1>
                {webinar.date && <div className="page-detail__date">{webinar.date}</div>}
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--classic">
            <div className="text-scroll" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase webinar">Webinar</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase webinar">Webinar</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__intro">
          <div className="container-lg">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column flex-md-row align-items-md-end position-relative">
                  {/* {post.category && <div className="page-detail__category">{post.category}</div>} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: webinar.abstract?.childMarkdownRemark?.html,
                    }}
                    className="page-detail__intro-text"
                  ></div>
                  <div className="page-detail__img-wrap">
                    <GatsbyImage
                      image={getImage(webinar.image)}
                      alt={webinar.image.description}
                      title={webinar.image.title}
                      objectPosition="center center"
                      className="page-detail__img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__text">
          <div className="container-lg pb-4">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1  col-lg-8 offset-lg-2">
                <div
                  dangerouslySetInnerHTML={{ __html: webinar.text?.childMarkdownRemark?.html }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg mb-5">
        <div className="row pb-4">
          <div className="col-12 text-center">
            <button
              type="button"
              title="Richiesta video webinar"
              aria-label="modale che mostra il form di richiesta"
              className="btn-link btn-link--big submit-click-tracking-ga"
              data-ga-id="button view form webinar"
              onClick={() => setShow(true)}
            >
              Guarda il video del webinar
            </button>
            {webinar.duration && <div className="page-detail__duration">{webinar.duration}</div>}
          </div>
        </div>
        <div className="row pt-3 mb-5">
          <div className="col-12 text-center mb-5">
            <Link to="/webinar/" title="Scopri altri webinar">
              <span>Scopri altri webinar</span>
            </Link>
          </div>
        </div>
      </div>

      <FormOverlay
        show={show}
        contentType="webinar"
        contentfulId={webinar.contentful_id}
        onHide={() => setShow(false)}
      />
    </Layout>
  );
};

export default WebinarDetail;

export const query = graphql`
  query ($contentful_id: String) {
    webinar: contentfulWebinar(contentful_id: { eq: $contentful_id }) {
      contentful_id
      title
      description
      abstract {
        childMarkdownRemark {
          html
        }
      }
      image {
        title
        description
        url
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      date(formatString: "DD MMMM YYYY", locale: "it")
      category
      link
      text {
        childMarkdownRemark {
          html
        }
      }
      video
      duration
    }
  }
`;
