import React, { useState } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const FormRisorse = ({ contentType, contentfulId }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      privacy: true,
    },
    mode: "onChange",
  });

  const [submitError, setSubmitError] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("risorseForm");
    setSubmitError(false);
    try {
      const response = await fetch("/api/form-risorse", {
        method: "POST",
        body: JSON.stringify({
          ...data,
          contentType,
          contentfulId,
          captcha: token,
        }),
      });
      if (!response.ok) {
        throw new Error(
          `Error calling /api/form-risorse: ${response.status} ${response.statusText}`
        );
      }
      const result = await response.json();
      console.log("result: ", result);
      // setSubmitted(true);
      navigate(`/thankyou-${contentType}/`, { state: { risorsaLink: result?.risorsaLink } });
    } catch (err) {
      setSubmitError(true);
      console.error("Error form risorse: ", err);
    }
  };

  return (
    <div className="modal__scroll">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-10 offset-xl-1">
            <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="form__legend">Guarda il video del webinar</div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="nome"
                        id="nome"
                        placeholder=" "
                        {...register("nome", {
                          required: {
                            value: true,
                            message: "Specificare un nome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un nome (solo caratteri e/o spazi).",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="nome">
                        Nome *
                      </label>
                    </div>
                    {errors.nome ? (
                      <span className="d-block form__error">{errors.nome.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="cognome"
                        id="cognome"
                        placeholder=" "
                        {...register("cognome", {
                          required: {
                            value: true,
                            message: "Specificare un cognome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un cognome (solo caratteri e/o spazi).",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="cognome">
                        Cognome *
                      </label>
                    </div>
                    {errors.cognome ? (
                      <span className="d-block form__error">{errors.cognome.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="azienda"
                        id="azienda"
                        placeholder=" "
                        {...register("azienda", {
                          required: {
                            value: true,
                            message: "Specificare l'azienda.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="azienda">
                        Azienda *
                      </label>
                    </div>
                    {errors.azienda ? (
                      <span className="d-block form__error">{errors.azienda.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="email"
                        className="form__input-text"
                        name="email"
                        id="email"
                        placeholder=" "
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Specificare un indirizzo email.",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Specificare un indirizzo email valido.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="email">
                        Email *
                      </label>
                    </div>
                    {errors.email ? (
                      <span className="d-block form__error">{errors.email.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__group">
                    <label className="checkbox">
                      <div>
                        Confermo di aver preso visione dell'informativa sul trattamento dei dati
                      </div>
                      <input
                        type="checkbox"
                        name="privacy"
                        id="privacy"
                        className="checkbox__input"
                        {...register("privacy", {
                          required: {
                            value: true,
                            message: "Confermare la presa visione dell'informativa.",
                          },
                        })}
                      />
                      <span className="checkbox__mark"></span>
                    </label>
                    {errors.privacy ? (
                      <span className="d-block form__error">{errors.privacy.message}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn-link btn-link--submit submit-click-tracking-ga"
                      data-ga-id="submit button form risorse"
                    >
                      Invia
                    </button>
                  </div>
                  {submitError && (
                    <div className="text-center mt-4">Ops, si è verificato un errore. Riprova.</div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormRisorse;
