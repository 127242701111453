import React from "react";
import Modal from "react-overlays/Modal";
import classNames from "classnames";
import FormRisorse from "../components/form-risorse";

import CloseIcon from "../icons/xmark-regular.svg";

const renderBackdrop = (props) => <div className="backdrop" {...props} />;

const FormOverlay = ({ show, contentType, contentfulId, onHide, className }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="modal-label"
        className={classNames("modal", className)}
        renderBackdrop={renderBackdrop}
        aria-hidden="false"
        aria-modal="true"
      >
        <div className="modal__body">
          <button
            type="button"
            onClick={onHide}
            className="modal__close close-click-tracking-ga"
            aria-label="Chiudi"
            data-ga-id={`close modal risorse`}
          >
            <CloseIcon className="ico-svg" />
          </button>

          <FormRisorse contentType={contentType} contentfulId={contentfulId} />
        </div>
      </Modal>
    </>
  );
};

export default FormOverlay;
